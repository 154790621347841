import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import App from './App';
import Footer from './components/Footer.js';
import { NewCampaign, OngoingCampaign, SharedCampaign, loader as campaignLoader } from './trackers/campaigns.js';
import { HelperPage, PrivacyPage, TrackingGameListPage, loader as helperLoader } from './pageDefinitions.js';
import EnemyIndex from './isofarianGuard/isofarianGuard_enemyIndex.js';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/privacy",
        element: <PrivacyPage />,
    },
    {
        path: "/trackingList",
        element: <TrackingGameListPage />
    },
    {
        path: "/tracker/:gameId/new",
        element: <NewCampaign />,
        loader: campaignLoader
    },
    {
        path: "/tracker/:gameId/:id",
        element: <OngoingCampaign />,
        loader: campaignLoader
    },
    {
        path: "/shared/:gameId/:id",
        element: <SharedCampaign />,
        loader: campaignLoader
    },
    {
        path: "/isofarianGuard/enemyIndex",
        element: <EnemyIndex />
    },
    {
        path: "/helper/:gameId",
        element: <HelperPage />,
        loader: helperLoader
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <div className="is-flex-direction-column">
            <RouterProvider router={router} />
            {document.location.href.includes('/helper') ? null : <Footer />}
        </div>
    </React.StrictMode>
);



import { signInWithPopup, onAuthStateChanged, signOut, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { auth, db } from '../utils/firebase.js';
import { OAuthProvider } from "firebase/auth";
import React, { useState } from 'react';
import { addDoc, setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { todayMMDDYYYY } from '../utils/getToday.js';
import { toggleModal } from '../utils/uiUtils.js';
import { GoogleLoginButton, YahooLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";
import { postSignInCheck } from '../data/users.js';

function AuthenticationButtons(props) {
    const [user, setUser] = useState(auth.currentUser);
    const navigate = useNavigate();

    const googleHandler = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        toggleModal("modal-loginOptions");
        provider.setCustomParameters({ prompt: 'select_account' });
        await signInWithPopup(auth, provider)
            .then(async (result) => {
                await postSignInCheck(result.user.uid);
                navigate('/trackingList')
            }).catch((exception) => {
                console.log(exception);
            });
    };

    const yahooHandler = async () => {
        toggleModal("modal-loginOptions");
        const yahooProvider = new OAuthProvider('yahoo.com');
        yahooProvider.setCustomParameters({ prompt: 'login' });
        yahooProvider.addScope('openid');
        await signInWithRedirect(auth, yahooProvider);
        navigate('/trackingList')
    };

    const microsoftHandler = async () => {
        toggleModal("modal-loginOptions");
        const msProvider = new OAuthProvider('microsoft.com');
        msProvider.setCustomParameters({ prompt: 'consent' });
        msProvider.addScope('openid');

        await signInWithPopup(auth, msProvider)
            .then(async (result) => {
                await postSignInCheck(result.user.uid);
                navigate('/trackingList')
            }).catch((exception) => {
                console.log(exception);
            });
    };

    const logOut = async () => {
        signOut(auth)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {

            });
    }

    onAuthStateChanged(auth, (user) => {
        setUser(user);
        if (user) {
            var lastLogin = localStorage.getItem("lastLogin");
            if (lastLogin !== todayMMDDYYYY) {
                const userRef = doc(db, 'users', user.uid);
                getDoc(userRef).then((result) => {
                    var userSnapshot = result;
                    var docSnapshot = userSnapshot.data();
                    var userData = { lastLogin: new Date() };
                    updateDoc(userRef, userData);
                    localStorage.setItem("lastLogin", todayMMDDYYYY)
                });
            } else {
                postSignInCheck(user.uid);
            }
        }
    });

    return (
        <>
            <div className="buttons">
                <button className={"button is-dark mt-0 " + (user ? "" : " is-hidden")} onClick={logOut}>Log Out</button>
            </div>
        </>
    );
}

export default AuthenticationButtons;
import { db } from '../utils/firebase.js';
import { orderBy, doc, query, addDoc, setDoc, getDoc, updateDoc, collection, getDocs, where, limit } from "firebase/firestore";
import { auth } from '../utils/firebase.js';
import { DefaultGameObject, DefaultPlayerObject } from '../objects/initial_database_structure.js';
import { todayMMDDYYYY } from '../utils/getToday.js';

const _incrementGamesPlayed = async (gameId, isAdHocGame) => {
    // add the score game to the collection
    var uid = auth.currentUser.uid;

    // now add the game to the played games map in the user doc
    const userRef = doc(db, "users", uid);
    var userRecord = await getDoc(userRef).then((snapshot) => {
        return snapshot.data();
    });

    var allGames = isAdHocGame ? userRecord.adHocGamesPlayed : userRecord.gamesPlayed;
    if (!allGames) {
        allGames = {};
    }

    allGames[gameId] = (allGames[gameId] ?? 0) + 1;
    if (isAdHocGame) {
        await updateDoc(userRef, { adHocGamesPlayed: allGames });
    } else {
        await updateDoc(userRef, { gamesPlayed: allGames });
    }
}

export const _savePlayerGame = async (game) => {
    // Get game based on ID
    var uid = auth.currentUser.uid;
    const gameRef = doc(db, "users", uid, "games", game.gameId);
    var gameDoc = await getDoc(gameRef);
    var thisGame = {};
    // if not exist, make new object
    if (!gameDoc._document) {
        thisGame = DefaultGameObject;
        thisGame.highestScore = game.winningScore;
        if (game.isCoop) {
            delete thisGame.highestScorer;
            thisGame.isCoop = true;
        } else {
            thisGame.highestScorer = game.winners?.join(" ") ?? "";
        }

        thisGame.lastPlayed = game.datePlayed;
        thisGame.name = game.game;
    } else {
        thisGame = gameDoc.data();
        thisGame.lastPlayed = game.datePlayed;
        if (thisGame.highestScore < game.winningScore) {
            if (!game.isCoop) {
                thisGame.highestScorer = game.winners?.join(" ");
            }
            thisGame.highestScore = game.winningScore;
        }
    }
    var fireStorePath = game.isAdHocGame ? "adHocGames" : "games";
    await setDoc(doc(db, "users", uid, fireStorePath, game.gameId), thisGame, { merge: true });
    
    // New plays collection document
    var newPlay = {
        "datePlayed": todayMMDDYYYY,
        "winners": game.winners,
        "players": game.players
    }

    if (game.isCoop) {
        newPlay.finalScore = game.winningScore;
        newPlay.outcome = game.outcome ?? "unsure";
        delete newPlay.winners;
    }
    await addDoc(collection(db, "users", uid, fireStorePath, game.gameId, 'plays'), newPlay);
    await _incrementGamesPlayed(game.gameId, game.isAdHocGame)
}

export const _savePlayerNames = async (playerNameArray, gameId, winners) => {
    var uid = auth.currentUser.uid;

    /// Get all players for this user who were in the game
    const queryCollection = query(collection(db, "users", uid, "players"), where('__name__', "in", playerNameArray));
    const querySnapshot = await getDocs(queryCollection);
    querySnapshot.forEach((player) => {
        var playerData = player.data();
        playerData.lastPlayed = todayMMDDYYYY;
        playerData.plays = (playerData.plays ?? 0) + 1
        if (winners && winners.includes(playerData)) {
            playerData.wins = (playerData.wins ?? 0) + 1;
        }

        playerData.games[gameId] = (playerData.games[gameId] ?? 0) + 1;
        setDoc(doc(db, "users", uid, "players", player.id), playerData, { merge: true });
        var indexOfPlayer = playerNameArray?.indexOf(player.id);
        playerNameArray.splice(indexOfPlayer, 1);
    });

    // need to know and update ones that are new
    playerNameArray.forEach(player => {
        var newPlayer = DefaultPlayerObject;
        newPlayer.games[gameId] = 1;
        newPlayer.lastPlayed = todayMMDDYYYY;
        newPlayer.plays = 1;
        newPlayer.wins = winners.includes(player) ? 1 : 0;

        setDoc(doc(db, "users", uid, "players", player), newPlayer, { merge: true });
    });
}

export const _getPlayerNames = async () => {
    var uid = auth.currentUser.uid;
    var allPlayers = [];
    const queryCollection = query(collection(db, "users", uid, "players"), orderBy("plays", "desc"));
    const querySnapshot = await getDocs(queryCollection);
    querySnapshot.forEach((player) => {
        allPlayers.push(player.id);
    });

    return allPlayers;
}

export const _getUserGames = async () => {
    var uid = auth.currentUser.uid;
    const userRef = doc(db, "users", uid);
    var userDoc = await getDoc(userRef);
    if (!userDoc?._document) {
        return {};
    }

    var userRecord = userDoc.data();
    var allGames = { ...userRecord.gamesPlayed ?? {}, ...userRecord.adHocGamesPlayed ?? {} };
    if (!allGames) {
        allGames = {};
    }

    return allGames;
}

export const _recentlyPlayed = async (returnLimit) => {
    var uid = auth.currentUser.uid;
    var allGames = [];
    const queryCollection = query(collection(db, "users", uid, "games"), orderBy("lastPlayed", "desc"), limit(returnLimit));
    const querySnapshot = await getDocs(queryCollection);
    querySnapshot.forEach((game) => {
        var gameData = game.data();
        var tmpGame = {
            id: game.id,
            name: gameData.name,
            lastPlayed: gameData.lastPlayed
        }
        allGames.push(tmpGame);
    });

    return allGames;
}

export const _frequentlyPlayed = async (returnLimit) => {
    var uid = auth.currentUser.uid;

    const userRef = doc(db, "users", uid);
    var userDoc = await getDoc(userRef);
    if (!userDoc?._document) {
        return {};
    }

    let sortable = [];

    var userRecord = userDoc.data();
    var allGames = { ...userRecord.gamesPlayed ?? {}, ...userRecord.adHocGamesPlayed ?? {} };
    for (var game in allGames ?? {}) {
        sortable.push([game, allGames[game]]);
    }

    sortable.sort(function (a, b) {
        if (a[1] == b[1]) {
            if (a[0] < b[0]) {
                return -1;
            }
            if (a[0] > b[0]) {
                return 1;
            }

            return 0;
        }

        return b[1] - a[1];
    });

    if (sortable.length > returnLimit) {
        sortable.splice(returnLimit);
    }

    return sortable;
}

export const _canCreateGame = async () => {
    var uid = auth.currentUser.uid;
    const userRef = doc(db, "roles", uid);
    var userDoc = await getDoc(userRef);
    if (!userDoc?._document) {
        return false;
    }

    var userRecord = userDoc.data();
    return userRecord.createGame;
}

export const postSignInCheck = async (uid) => {
    const userRef = doc(db, 'users', uid);
    var userSnapshot = await getDoc(userRef);
    var docSnapshot = userSnapshot?.data();
    var userData = { lastLogin: new Date() };
    if (!docSnapshot) {
        userData.dateJoined = new Date();
        await setDoc(userRef, userData);
    } else {
        await updateDoc(userRef, userData);
    }
}
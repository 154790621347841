import { db } from '../utils/firebase.js';
import { doc, addDoc, getDocs, getDoc, updateDoc, collection, query, where, orderBy, runTransaction, deleteDoc } from "firebase/firestore";
import { auth } from '../utils/firebase.js';

export const _getGameTrackerList = async () => {
    const trackerQuery = query(collection(db, "gameTracking"), where("published", "==", true), orderBy("name"));
    var gameList = [];
    const querySnapshot = await getDocs(trackerQuery);

    querySnapshot.forEach((doc) => {
        var trackerData = doc.data();
        var tempGame = {
            id: doc.id,
            name: trackerData.name
        };

        gameList.push(tempGame);
    });

    return gameList;
}

export const _getGameTrackerGame = async (gameId) => {
    const docRef = doc(db, "gameTracking", gameId);
    const docSnap = await getDoc(docRef);
    var x =  await docSnap.data();
    return x;
}

export const _addNewUserTracker = async (newTracker) => {
    // add the new tracker to the list

    var uid = auth.currentUser.uid;
    const docRef = await addDoc(collection(db, "users", uid, 'trackers'), newTracker);
    return docRef.id;
}

export const _getGame = async (gameId) => {
    var uid = auth.currentUser.uid;
    const docRef = doc(db, "users", uid, "trackers", gameId);
    const docSnap = await getDoc(docRef);
    var game = await docSnap.data();
    return game;
}

export const _saveUserGameTracker = async (trackerId, tracker) => {
    var uid = auth.currentUser.uid;
    const docRef = doc(db, "users", uid, "trackers", trackerId);
    await updateDoc(docRef, tracker);
    return;
}

export const _getUserGameTrackerList = async () => {
    var uid = auth.currentUser.uid;
    const trackerQuery = query(collection(db, "users", uid, "trackers"), where("campaignOutcome", "==", "Ongoing"), orderBy("gameId"));

    const querySnapshot = await getDocs(trackerQuery);
    var gameList = [];
    querySnapshot.forEach((doc) => {
        var tempGame = {
            id: null,
            campaignName: null,
            name: null,
            gameId: null

        };
        const gameData = doc.data();
        tempGame.id = doc.id;
        tempGame.name = gameData.name;
        tempGame.gameId = gameData.gameId;
        tempGame.campaignName = gameData.campaignName;
        gameList.push(tempGame);
    });

    return gameList;
}

export const _getGameRef = async (gameId) => {
    var uid = auth.currentUser.uid;
    const docRef = doc(db, "users", uid, "trackers", gameId);
    return docRef;
}

export const _shareCampaign = async (trackerId, campaignName, gameFriendlyName, gameId, shareWithEmail) => {
    var uid = auth.currentUser.uid;
    const docRef = doc(db, "users", uid, "trackers", trackerId);

    var sharedTracker = {
        campaignName: campaignName,
        gameFriendlyName: gameFriendlyName,
        gameId: gameId,
        sharedWith: [shareWithEmail],
        tracker: docRef
    }

    const docSnap = await getDoc(docRef);
    const game = docSnap.data();
    var sharedDocumentId = game.sharedDocumentId;
    if (sharedDocumentId) {
        const sharedDocRef = doc(db, "sharedTrackers", game.sharedDocumentId);
        const sharedDocSnap = await getDoc(sharedDocRef);
        const sharedTrackerData = sharedDocSnap.data();
        sharedTrackerData.sharedWith.push(shareWithEmail);
        await updateDoc(sharedDocRef, sharedTrackerData, { merge: true });
    } else {
        const sharedDocRef = await addDoc(collection(db, "sharedTrackers"), sharedTracker);
        sharedDocumentId = sharedDocRef.id;
        await updateDoc(docRef, game);
    }

    return sharedDocumentId;
}

export const _stopSharingCampaign = async (gameId) => {
    // get shared document id from the game
    var uid = auth.currentUser.uid;
    const docRef = doc(db, "users", uid, "trackers", gameId);
    const docSnap = await getDoc(docRef);
    const game = docSnap.data();
    var trackerId = game.sharedDocumentId;

    // delete the shared document
    const sharedDocRef = doc(db, "sharedTrackers", trackerId);
    await deleteDoc(sharedDocRef);

    // clear sharing data from the campaign
    game.sharedDocumentId = null;
    game.isShared = false;
    game.editors = [];
    await updateDoc(docRef, game);
}

export const _removeSharing = async (gameId, trackerId, shareWithEmail) => {
    var uid = auth.currentUser.uid;
    const docRef = doc(db, "users", uid, "trackers", gameId);
    const docSnap = await getDoc(docRef);
    const game = docSnap.data();

    // remove the email from the shared document
    const sharedDocRef = doc(db, "sharedTrackers", trackerId);
    const sharedDocSnap = await getDoc(sharedDocRef);
    const sharedTrackerData = sharedDocSnap.data();
    sharedTrackerData.sharedWith = sharedTrackerData.sharedWith.filter(email => email !== shareWithEmail);
    await updateDoc(sharedDocRef, sharedTrackerData);

    // remove the email from the game
    game.editors = game.editors.filter(email => email !== shareWithEmail);
    await updateDoc(docRef, game);
}

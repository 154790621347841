export const IsofarianGuard_NodeConnections =
{
    "1": ["7"],
    "5": ["10"],
    "6": ["8"],
    "7": ["1", "14"],
    "8": ["6", "23"],
    "10": ["5", "14"],
    "11": ["10", "27"],
    "12": ["13"],
    "13": ["12", "18", "19"],
    "14": ["7", "10"],
    "18": ["13", "19", "21"],
    "21": ["18", "42"],
    "23": ["8"],
    "26": ["28"],
    "27": ["11", "39"],
    "28": ["26", "30", "38"],
    "30": ["28", "31", "38"],
    "31": ["30"],
    "35": ["40"],
    "37": ["38", "44"],
    "38": ["28", "30", "37", "46"],
    "39": ["27", "47"],
    "40": ["35"],
    "42": ["21", "50", "82"],
    "44": ["37", "52", "64"],
    "46": ["38"],
    "47": ["39", "66"],
    "50": ["42"],
    "52": ["44", "53", "64"],
    "53": ["52", "61"],
    "57": ["58", "62"],
    "58": ["57"],
    "61": ["53", "73"],
    "62": ["57", "67", "68"],
    "63": ["68"],
    "64": ["44", "52", "72"],
    "65": ["66", "89"],
    "66": ["47", "65", "80", "89"],
    "67": ["62"],
    "68": ["62", "63"],
    "72": ["64", "73"],
    "73": ["61", "72"],
    "76": ["77"],
    "77": ["76"],
    "80": ["39", "66", "82", "89"],
    "82": ["42", "80"],
    "83": ["84", "90"],
    "84": ["83", "85"],
    "85": ["84", "91"],
    "87": ["93"],
    "89": ["65", "80"],
    "90": ["83", "91", "97"],
    "91": ["85", "90", "92"],
    "92": ["91", "93"],
    "93": ["87", "92"],
    "97": ["90", "99"],
    "99": ["97"],
    "103": ["104"],
    "104": ["103", "105"],
    "105": ["104"],
    "Urok Span": ["Ice Fields", "Reka Glacier", "The Broken Lands", "Uchitel Span"],
    "Ice Fields": ["The Broken Lands", "Urok Span"],
    "Reka Glacier": ["Uchitel Span", "Urok Span"],
    "Uchitel Span": ["Reka Glacier", "The Broken Lands", "Urok Span", "Vniz Path"],
    "The Broken Lands": ["Ice Fields", "Room of Columns", "Uchitel Span", "Urok Span"],
    "Vniz Path": ["Skryvat Temple", "Uchitel Span"],
    "Skryvat Temple": ["Room of Columns", "Vniz Path"],
    "Room of Columns": ["Skryvat Temple", "The Broken Lands"]
}
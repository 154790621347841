import { Suspense } from 'react';
import { useLoaderData } from "react-router-dom";
import SiteNav from './components/SiteNav.js';
import Privacy from './components/privacyPage.js';
import TrackingGameList from './components/TrackingGameList.js';
import { getHelperComponent } from './utils/helperComponents.js';
import Loading from './components/Loading.js';

export async function loader({ params }) {
    return { gameId: params.gameId, campaignId: params.id };
}

export const TrackingGameListPage = () => {
    return (
        <>
            <SiteNav />
            <TrackingGameList />
        </>
        )
}

export const PrivacyPage = () => {
    return (
        <>
            <SiteNav />
            <Privacy />
        </>
        )
}

export const HelperPage = () => {
    const gameId = useLoaderData().gameId;
    const GameComponent = getHelperComponent(gameId);
    return (
        <div className="m-0 p-0" >
            {GameComponent ? (
                <Suspense fallback={<Loading />}>
                    <GameComponent />
                </Suspense>
            ) : (
                <div>Game ID not found</div>
            )}
        </div>
    );
}

export const DefaultGameObject =
{
    "highestScore": 0,
    "highestScorer": "",
    "lastPlayed": "",
    "name": ""
}

export const DefaultPlayerObject =
{
    "games": {},
    "lastPlayed": "",
    "plays": 0,
    "wins": 0
}
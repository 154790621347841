export function DesktopCampaignGames() {
    return (
        <div className="bgt-box-rounded has-background-primary has-text-primary-light p-2">
            <div className="mr-auto ml-auto is-fullwidth has-text-centered">
                <div className="is-size-4 has-text-weight-bold">Available Game Campaigns</div>
            </div>
            <div className="columns is-multiline">
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/escapeStalingradZ_NewGameBox.18155aa0c131bbe7e6ee.png" height="100" alt="Escape From Stalingrad Z" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/hoplomachusV_NewGameBox.aba1aa2ba7e2469fe077.png" height="100" alt="Hoplomachus Victorum" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/isofarianGuard_NewGameBox.bec2861b53ae907cff7b.png" height="100" alt="Isofarian Guard" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/mhw_NewGameBox.a06f494ebef4996a2708.png" height="100" alt="Monster Hunter World" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/novaAetasRenaissance_NewGameBox.607baa479be9310e27fe.png" height="100" alt="Nova Aetas Renaissance" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/primalAwakening_NewGameBox.db661de46e2121853336.png" height="100" alt="Primal The Awakening" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-one-third-tablet">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/lludwiksLabyrinth_NewGameBox.51d2613cf51546f11dfc.png" height="100" alt="Valor and Villainy: Lludwik's Labyrinth" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function MobileCampaignGames() {
    return (
        <>
            <div className="is-size-4 has-text-weight-bold">Available Game Campaigns</div>
            <div className="columns is-multiline is-mobile">
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="300">
                                <img src="/static/media/escapeStalingradZ_NewGameBox.18155aa0c131bbe7e6ee.png" height="300" alt="Escape From Stalingrad Z" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/hoplomachusV_NewGameBox.aba1aa2ba7e2469fe077.png" height="100" alt="Hoplomachus Victorum" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/isofarianGuard_NewGameBox.bec2861b53ae907cff7b.png" height="100" alt="Isofarian Guard" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/mhw_NewGameBox.a06f494ebef4996a2708.png" height="100" alt="Monster Hunter World" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/novaAetasRenaissance_NewGameBox.607baa479be9310e27fe.png" height="100" alt="Nova Aetas Renaissance" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/primalAwakening_NewGameBox.db661de46e2121853336.png" height="100" alt="Primal The Awakening" />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="column is-one-quarter-desktop is-half-mobile">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-3by1" height="100">
                                <img src="/static/media/lludwiksLabyrinth_NewGameBox.51d2613cf51546f11dfc.png" height="100" alt="Valor and Villainy: Lludwik's Labyrinth" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export const toggleModal = (modalId) => {
    if (document.getElementById(modalId).classList.contains("is-active")) {
        document.getElementById(modalId).classList.remove("is-active");
    } else {
        document.getElementById(modalId).classList.add("is-active")
    }
}

export const changeTab = (activeTab) => {
    Array.from(document.querySelectorAll('.tabHeader')).forEach(
        (el) => el.classList.remove('is-active')
    );

    document.getElementById(activeTab + "TabHeader").classList.add("is-active");

    Array.from(document.querySelectorAll('.content-tab')).forEach(
        (el) => el.classList.add('is-hidden')
    );

    document.getElementById(activeTab + "Content").classList.remove("is-hidden");
}


export const changeSubTab = (activeTab) => {
    Array.from(document.querySelectorAll('.subTabHeader')).forEach(
        (el) => el.classList.remove('is-active')
    );

    document.getElementById(activeTab + "TabHeader").classList.add("is-active");

    Array.from(document.querySelectorAll('.content-sub-tab')).forEach(
        (el) => el.classList.add('is-hidden')
    );

    document.getElementById(activeTab + "Content").classList.remove("is-hidden");
}

export const makeTimePretty = (dateToPrettify) => {
    var dateTime = new Date(dateToPrettify);
    return dateTime.toLocaleString(); 
}

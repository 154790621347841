import React from 'react';
import { Suspense } from 'react';
import '../App.css';
import SiteNav from '../components/SiteNav.js';
import { useLoaderData } from "react-router-dom";
import { getNewGameComponent, getOngoingGameComponent } from '../utils/gameComponents.js';
import { _getGame, _saveUserGameTracker, _getGameRef } from '../data/gameTrackers.js';
import { _getSharedGame, _saveSharedUserGameTracker, _getSharedGameRef } from '../data/sharedGameTrackers.js';
import Loading from '../components/Loading.js';

export async function loader({ params }) {
    return { gameId: params.gameId, campaignId: params.id };
}

export const NewCampaign = () => {
    const gameId = useLoaderData().gameId;
    const GameComponent = getNewGameComponent(gameId);
    return (
        <div>
            <SiteNav />
            {GameComponent ? (
                <Suspense fallback={<Loading />}>
                    <GameComponent />
                </Suspense>
            ) : (
                <div>Game ID not found</div>
            )}
        </div>
    );
}

export const OngoingCampaign = () => {
    const gameId = useLoaderData().gameId;
    const campaignId = useLoaderData().campaignId;
    const GameComponent = getOngoingGameComponent(gameId);

    return (
        <div>
            <SiteNav />
            {GameComponent ? (
                <Suspense fallback={<Loading />}>
                    <GameComponent gameId={campaignId} _getGame={_getGame} _saveUserGameTracker={_saveUserGameTracker} _getGameRef={_getGameRef} />
                </Suspense>
            ) : (
                <div>Game ID not found</div>
            )}
        </div>
    );
}

export const SharedCampaign = () => {
    const gameId = useLoaderData().gameId;
    const trackerId = useLoaderData().campaignId;
    const GameComponent = getOngoingGameComponent(gameId);

    return (
        <div>
            <SiteNav />
            {GameComponent ? (
                <Suspense fallback={<Loading />}>
                    <GameComponent gameId={trackerId} _getGame={_getSharedGame} _saveUserGameTracker={_saveSharedUserGameTracker} _getGameRef={_getSharedGameRef} />
                </Suspense>
            ) : (
                <div>Game ID not found</div>
            )}
        </div>
    );
}

import React from 'react';
import LogoOnly from '../media/logoonly.png';

function Loading() {
    return (
        <div className="container has-text-centered is-vcentered">
            <img alt="Loading..." className="rotate mb-5" src={LogoOnly}></img>
            <br></br>
                Loading...            
        </div>
    );
}

export default Loading;
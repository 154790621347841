import React, { useEffect, useState } from 'react';
import './App.css';
import './styles/boardGameTally.css';
import SiteNav from './components/SiteNav.js'
import Splash from './components/splash.js';

function App() {

    return (
        <>
            <SiteNav />
            <div className='container is-fullheight'>
                <Splash />
            </div>
        </>
    )
}

export default App; 

import React from 'react';

function Privacy(props) {

    return (
        <>
            <div id="modal-whylogin" className="container">

                <h5 className="mb-0 title is-5">Privacy</h5>
                <span className="is-size-6">
                    <li className="mt-0">This site does not keep any personal data. When you log in with Google, Yahoo or Microsft we don't store your email, name, or any information from your account.</li>
                    <li>We save the following information:</li>
                    <div className="is-list ml-5">
                        <li>The games you played and how often</li>
                        <li>The player names you entered manually</li>
                        <li>The date you first logged in, and the last date you logged in</li>                        
                        <li>Whatever information you enter in a campaign tracker for a game</li>
                    </div>

                    <li>The information we do have will never be shared with any third party sites</li>
                    <li>We do not do analytics or tracking, and do not do any form of advertising</li>
                </span>
            </div>
        </>
    );
}

export default Privacy;
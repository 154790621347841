import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { auth } from "../utils/firebase.js";
import { onAuthStateChanged } from 'firebase/auth';
import { _getGameTrackerList, _getUserGameTrackerList } from '../data/gameTrackers.js';
import { _getGamesSharedWithUser } from '../data/sharedGameTrackers.js';

import Loading from './Loading.js';

const TrackingGameList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [trackerList, setTrackerList] = useState();
    const [userTrackingList, setUserTrackingList] = useState();
    const [sharedCampaignList, setSharedCampaignList] = useState();

    const images = importAll(require.context('../media/trackerBox', false, /\.(png|jpe?g|svg)$/));

    const getGameName = (gameId) => {
        var name = trackerList.find(({ id }) => id === gameId)?.name;
        if (!name) {
            name = gameId;
        }

        return name;
    }

    useEffect(() => {
        var initGames = async () => {
            var gameTrackerList = await _getGameTrackerList();
            setTrackerList(gameTrackerList);
            var userGameTrackerList = await _getUserGameTrackerList();
            setUserTrackingList(userGameTrackerList);
            var sharedCampaigns = await _getGamesSharedWithUser();
            setSharedCampaignList(sharedCampaigns);
            setIsLoading(false);
        }

        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
                initGames();
            } else {
                setIsLoggedIn(false);
                setTrackerList({});
            }
        })
    }, []);

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }


    if (isLoading) {
        return (<Loading></Loading>)
    }

    return (
        <>
            <div className="container pl-1 pr-1">
                {isLoggedIn && userTrackingList?.length > 0 &&
                    <>
                        <div className="divider is-dark">Your Current Campaigns</div>
                        <div className="columns is-multiline">
                            {userTrackingList.map(function (item, index) {
                                return (
                                    <div key={"tracker" + index} className="column is-one-quarter-desktop is-one half-mobile">
                                        <div className="card">
                                            <div className="card-image">
                                                <Link to={`/tracker/${item.gameId}/${item.id}`}>
                                                    <figure className="image is-3by1" height="100">
                                                        <img src={images[item.gameId + "_NewGameBox.png"]} height="100" alt={item.name} />
                                                    </figure>
                                                    <div className='has-text-centered has-text-weight-bold'>
                                                        {item.campaignName}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }
                {isLoggedIn && sharedCampaignList?.length > 0 &&
                    <>
                        <div className="divider is-dark">Campaigns Shared With You</div>
                        <div className="columns is-multiline">
                            {sharedCampaignList.map(function (item, index) {
                                return (
                                    <div key={"tracker" + index} className="column is-one-quarter-desktop is-one half-mobile">
                                        <div className="card">
                                            <div className="card-image">
                                                <Link to={`/shared/${item.gameId}/${item.trackerId}`}>
                                                    <figure className="image is-3by1" height="100">
                                                        <img src={images[item.gameId + "_NewGameBox.png"]} height="100" alt={item.gameFriendlyName} />
                                                    </figure>
                                                    <div className='has-text-centered has-text-weight-bold'>
                                                        {item.campaignName}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }

                {trackerList &&
                    <>
                        <div>
                            <div className="divider is-dark">Start New Campaign </div>
                        </div>
                        <div className="columns is-multiline">
                            {trackerList.map(function (item, index) {
                                return (
                                    <div key={"tracker" + index} className="column is-one-quarter-desktop is-one half-mobile ">
                                        <div className="card">
                                            <div className="card-image">
                                                <Link to={`/tracker/${item.id}/new`}>
                                                    <figure className="image is-3by1" height="100">
                                                        <img src={images[item.id + "_NewGameBox.png"]} height="100" alt={item.name} />
                                                    </figure>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default TrackingGameList;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { GetEnemyList } from '../data/isofarianGuard';
import logo from '../media/logo.png';
import Icon from '@mdi/react';
import { mdiStar, mdiSkull, mdiAccountAlert } from '@mdi/js';
import { IsofarianGuard_NodeConnections } from '../objects/isofarianGuard_nodeConnections.js';

function EnemyIndex() {
    const [enemyList, setEnemyList] = useState({});
    const [nodeEnemies, setNodeEnemies] = useState({});
    const [sectionList, setSectionList] = useState({});
    const [nodeList, setNodeList] = useState({});

    const getEnemiesByChapter = (chapter) => {
        if (chapter === "-1") {
            setNodeEnemies({});
            setNodeList({});
            setSectionList({});
            setEnemyList({});
            return;
        }

        GetEnemyList(chapter).then((data) => {
            setNodeEnemies({});
            setNodeList({});
            setEnemyList(data ?? {});
            setSectionList(Object.keys(data));
            document.getElementById("nodeList").value = -1;
            document.getElementById("sectionList").value = -1;
        })
    }

    const displayEnemyForNode = (node) => {
        var selectedSection = document.getElementById("sectionList").value;
        setNodeEnemies(enemyList[selectedSection][node]);
        document.getElementById("nodeList").value = node;
    }

    const displayNodesForSection = (section) => {
        if (section === "-1") {
            setNodeEnemies({});
            setNodeList({});
            return;
        }
        setNodeList(enemyList[section]);
        setNodeEnemies({});
    }

    const renderEnemyRank = (count, key) => {
        var returnArray = [];
        for (let i = 1; i <= count; i++) {
            returnArray.push(<Icon key={key + "_" + i} path={mdiStar} color="#CC9900" size={.70}></Icon>);
        }

        return (<>{returnArray}</>)
    }

    const renderEnemy = (enemyObject, skullCount) => {
        return (
            <>
                <div className="has-text-centered" >{enemyObject.enemies.map(function (item, enemyIndex) {
                    return (
                        <React.Fragment key={"nodeEnemies" + skullCount + enemyIndex}>
                            <span className="has-text-primary has-text-weight-semibold">{renderEnemyRank(item.rank, "nodeEnemies_skullCount" + skullCount + enemyIndex)} {item.name}</span><br />
                        </React.Fragment>
                    )
                })}
                </div>
                {enemyObject.isAmbush &&
                    <div className="has-text-centered">
                        <Icon path={mdiAccountAlert} className="has-text-danger" size={1.5} />
                        <div className="has-text-danger has-text-weight-bold">Ambush!</div>
                    </div>
                }
            </>
        )
    }

    const renderNodeConnections = () => {
        var buttonArray = [];
        var currentNode = document.getElementById("nodeList").value;
        var nodeConnections = IsofarianGuard_NodeConnections[currentNode] ?? [];
        if (nodeConnections.length === 0) {
            return;
        }

        for (let node in nodeConnections) {
            buttonArray.push(<button className="button is-info ml-5 is-outlined" onClick={() => { displayEnemyForNode(nodeConnections[node]) }}>{nodeConnections[node]}</button>)
        }

        return (<>{buttonArray}</>);
    }

    return (
        <>
            <section className="">
                <div className="navbar is-fixed-top has-background-primary" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <img className="image ml-4 mt-4 mb-2" alt="Board Game Tally Logo" src={logo} ></img>
                    </div>
                </div>
            </section>
            <div className="container ml-3 mr-5 pr-5">
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Select Chapter</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-narrow">
                            <div className="control">
                                <div className="select is-rounded is-primary is-fullwidth">
                                    <select id="chapterSelect" onChange={(event) => { getEnemiesByChapter(event.currentTarget.value) }}>
                                        <option value="-1"></option>
                                        <option value="chapter1">Chapter 1</option>
                                        <option value="chapter2">Chapter 2</option>
                                        <option value="chapter3">Chapter 3</option>
                                        <option value="chapter4">Chapter 4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"field is-horizontal " + (Object.keys(sectionList).length === 0 ? "is-hidden" : "")}>
                    <div className="field-label is-normal">
                        <label className="label">Select Section</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-narrow">
                            <div className="control">
                                <div className="select is-rounded is-primary is-fullwidth">
                                    <select id="sectionList" onChange={(event) => { displayNodesForSection(event.currentTarget.value) }}>
                                        <option value="-1"></option>
                                        {Object.keys(sectionList).sort(function (a, b) { return a - b }).map(function (item, index) {
                                            return (
                                                <option key={"sectionList" + index} value={sectionList[index]}>{sectionList[index]}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"field is-horizontal " + (Object.keys(nodeList).length === 0 ? "is-hidden" : "")}>
                    <div className="field-label is-normal">
                        <label className="label">Select Node</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-narrow">
                            <div className="control">
                                <div className="select is-rounded is-primary is-fullwidth">
                                    <select id="nodeList" onChange={(event) => { displayEnemyForNode(event.currentTarget.value) }}>
                                        <option value="-1"></option>
                                        {Object.keys(nodeList).sort(function (a, b) { return a - b }).map(function (item, index) {
                                            return (
                                                <option key={"enemyList" + index} value={item}>{item}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns is-multiline is-centered mt-4">
                    <div className="column has-text-centered">
                        <div className="mb-2">Connected Nodes</div>
                        {Object.keys(nodeList).length > 0 && renderNodeConnections()}
                    </div>
                </div>
                {
                    Object.keys(nodeEnemies).length > 0 &&
                    <div className="container">
                        <div className="columns is-multiline">
                            <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                                <div className="box has-background-primary-light bgt-box-rounded">
                                    <div className="is-size-6 is-underlined has-text-weight-medium has-text-centered">
                                        <Icon path={mdiSkull} className="has-text-primary-25" size={1} />
                                    </div>
                                    {renderEnemy(nodeEnemies["1skull"], 1)}
                                </div>
                            </div>
                            <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                                <div className="box has-background-primary-light bgt-box-rounded">
                                    <div className="is-size-6 is-underlined has-text-weight-medium has-text-centered">
                                        <Icon path={mdiSkull} className="has-text-primary-25" size={1} />
                                        <Icon path={mdiSkull} className="has-text-primary-25" size={1} />
                                    </div>
                                    {renderEnemy(nodeEnemies["2skull"], 2)}
                                </div>
                            </div>

                            <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                                <div className="box has-background-primary-light bgt-box-rounded">
                                    <div className="is-size-6 is-underlined has-text-weight-medium has-text-centered">
                                        <Icon path={mdiSkull} className="has-text-primary-25" size={1} />
                                        <Icon path={mdiSkull} className="has-text-primary-25" size={1} />
                                        <Icon path={mdiSkull} className="has-text-primary-25" size={1} />
                                    </div>
                                    {renderEnemy(nodeEnemies["3skull"], 3)}

                                </div>
                            </div>
                        </div>
                    </div>

                }

            </div>
        </>
    );
}

export default EnemyIndex;
import { lazy, Suspense } from 'react';

const newGameComponents = {
    mhw: lazy(() => import('../monsterhunter/monsterhunter_new.js')),
    hoplomachusV: lazy(() => import('../hoplomachusV/hoplomachusV_setup.js')),
    isofarianGuard: lazy(() => import('../isofarianGuard/isofarianGuard_setup.js')),
    lludwiksLabyrinth: lazy(() => import('../lludwiksLabyrinth/lludwiksLabyrinth_setup.js')),
    escapeStalingradZ: lazy(() => import('../escapeStalingradZ/escapeStalingradZ_new.js')),
    primalAwakening: lazy(() => import('../primalAwakening/primalAwakening_new.js')),
    novaAetasRenaissance: lazy(() => import('../novaAetasRenaissance/novaAetasRenaissance_setup.js'))
};

const ongoingGameComponents = {
    mhw: lazy(() => import('../monsterhunter/monsterhunter.js')),
    hoplomachusV: lazy(() => import('../hoplomachusV/hoplomachusV_game.js')),
    isofarianGuard: lazy(() => import('../isofarianGuard/isofarianGuard_game.js')),
    lludwiksLabyrinth: lazy(() => import('../lludwiksLabyrinth/lludwiksLabyrinth_campaign.js')),
    escapeStalingradZ: lazy(() => import('../escapeStalingradZ/escapeStalingradZ_campaign.js')),
    primalAwakening: lazy(() => import('../primalAwakening/primalAwakening_game.js')),
    novaAetasRenaissance: lazy(() => import('../novaAetasRenaissance/NovaAetasRenaissance_game.js'))
};

export function getNewGameComponent(gameId) {
  return newGameComponents[gameId] || null; // Handle non-existent gameIds
}

export function getOngoingGameComponent(gameId) {
  return ongoingGameComponents[gameId] || null; // Handle non-existent gameIds
}

